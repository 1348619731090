<script setup>
import { atcb_action } from "add-to-calendar-button"
import { format } from 'date-fns-tz'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'
const props = defineProps({
    event: Object,
    listed: Boolean
})
let { page } = useContent()

if (props.event) {
    page = ref(props.event)
}

const date = computed(() => {
    const eventDate = {}
    if (Array.isArray(page.value.date)) {
        eventDate.startDate = new Date(page.value.date[0])
        eventDate.endDate = new Date(page.value.date[1])
    } else {
        eventDate.startDate = new Date(page.value.date)
    }
    return eventDate
})
const longDate = computed(() => {
    let longDateText = ""
    longDateText = `${format(date.value.startDate, "EEEE")}`
    if (date.value?.endDate) {
        longDateText += `-${format(date.value.endDate, "EEEE")}`
    }
    longDateText += `, ${format(date.value.startDate, "LLLL")}`
    longDateText += ` ${format(date.value.startDate, "dd")}`
    if (date.value?.endDate) {
        longDateText += `-${format(date.value.endDate, "dd")}`
    }
    if (page.value.timezone) {
        const eventTime = date.value.startDate.toLocaleTimeString('en-US', { timeZone: page.value.timezone, timeZoneName: "short", hour: "2-digit", minute: "2-digit" })
        /* User have same timezone as event */
        if (Intl.DateTimeFormat().resolvedOptions().timeZone === page.value.timezone) {
            longDateText += ` (${eventTime})`
        } else {
            longDateText += ` (${eventTime}, ${format(date.value.startDate, 'HH:mm (z)')})`
        }
    }
    return longDateText
})

const addToCalendar = (ev) => {
    const config = {
        name: `[Reminder] ${page.value.title} (Pathway)`,
        description: `${page.value.title}, ${page.value.description}`,
        startDate: `${format(date.value.startDate, "yyyy-MM-dd")}`,
        options: ["Google", "Apple", "Outlook.com"],
        location: page.value.location.long
    }
    if (date.value?.endDate) {
        config.endDate = `${format(date.value.endDate, "yyyy-MM-dd")}`
    }
    if (page.value?.timezone) {
        config.timeZone = page.value.timezone
        const eventStartTime = date.value.startDate.toLocaleTimeString('en-US', { timeZone: page.value.timezone, hour: "2-digit", minute: "2-digit" })
        config.startTime = eventStartTime
        if (date.value?.endDate) {
            const eventEndTime = date.value.endDate.toLocaleTimeString('en-US', { timeZone: page.value.timezone, hour: "2-digit", minute: "2-digit" })
            config.endTime = eventEndTime
        }
    }
    atcb_action(config, ev.currentTarget)
}
</script>
<template>
    <div class="shadow-lg max-w-3xl w-full bg-white border-[#E0DEE2] border rounded-lg relative mx-auto my-8 eventcard">
        <div class="h-[9rem]">
            <div class="absolute flex items-center top-0 left-0 w-full p-2"
                :class="[listed ? 'justify-end' : 'justify-between']">
                <nuxt-link to="/events/" class="text-white" v-if="!listed">
                    <span class="flex items-center gap-2 shrink-0">
                        <Icon name="ion:arrow-back" class="w-5 h-5" /> All events
                    </span>
                </nuxt-link>
                <div class="flex gap-2 items-center drop-shadow-md">
                    <Icon name="mdi:event-star" class="text-white w-5 h-5" />
                    <span class="text-white text-sm">{{ format(date.startDate, 'dd/MM/yyyy') }}</span>
                </div>
            </div>
            <img :src="page.banner" alt="" class="h-[9rem] w-full object-cover rounded-t-lg">
        </div>
        <div class="flex items-center flex-col md:flex-row gap-4 pw-text-black p-4">
            <div
                class="flex flex-col shrink-0 leading-6 items-center justify-center w-[4.5rem] h-[4.5rem] bg-primary-100 rounded-lg text-primary-500 font-bold">
                <span class="text-[1.2rem]">{{ format(date.startDate, 'LLL') }}</span>
                <span class="text-[1.8rem]">{{ format(date.startDate, 'dd') }}</span>
            </div>
            <div class="flex flex-wrap md:flex-nowrap justify-between items-center w-full text-center lg:text-left">
                <div class="text-center mx-auto md:mx-0 lg:text-left">
                    <nuxt-link :to="page._path" class="font-bold text-2xl">
                        <h1>{{ page.title }}</h1>
                    </nuxt-link>
                    <p class="text-[#676161] pr-1">{{ page.description }}</p>
                </div>
                <div class="mx-auto mt-4 md:mt-0">
                    <button type="button"
                        class="shadow-md hover:shadow  text-sm transition flex gap-2 items-center px-4 py-2 rounded-lg whitespace-nowrap"
                        @click="addToCalendar">
                        <Icon name="material-symbols:calendar-add-on" /> Add to Calendar
                    </button>
                </div>

            </div>
        </div>
        <div class="border-t py-4 mx-4" v-if="!listed">
            <slot />
        </div>
        <div class="border-t py-4 mx-4">
            <div class="flex flex-col md:flex-row justify-between items-center text-[#928C99] leading-5">
                <div class="flex flex-col gap-2">
                    <div class="flex gap-2 items-center">
                        <Icon name="mingcute:time-line" class="w-8 h-8" />
                        <client-only>
                            <div class="flex flex-col">
                                <span>{{ longDate }}</span>
                                <span class="text-sm"> {{ formatDistanceToNow(date.startDate, { addSuffix: true }) }}
                                </span>
                            </div>
                        </client-only>
                    </div>
                    <div class="flex gap-2 items-center">
                        <Icon name="tdesign:location" class="w-8 h-8" />
                        <div class="flex flex-col">
                            <span> {{ page.location.short }} </span>
                            <span class="text-sm"> {{ page.location.long }} </span>
                        </div>
                    </div>
                </div>
                <nuxt-link :to="page._path" v-if="listed"
                    class="px-6 py-4 mt-4 w-full text-center md:w-max md:mt-0 bg-primary-100 text-primary-500 font-bold rounded-lg">
                    Read More
                </nuxt-link>
                <nuxt-link :to="page.href" v-else-if="page?.buttons?.signup"
                    class="px-6 py-4 mt-4 w-full text-center md:w-max max-w-xs md:mt-0 bg-primary-100 text-primary-500 font-bold rounded-lg">
                    {{ page.buttons.signup }}
                </nuxt-link>
            </div>
        </div>
    </div>
</template>
